 import React, {useState} from 'react';
 const AuthContext = React.createContext({
     token: '',
    isLoggedIn: false,
     login: (token) => {},
      logout: () => {},
 })
 
 
 export const AuthContextProvidedr = (props) => {
   const [token, setToken] = useState(localStorage.getItem("token"))
 
   const userIsLoggedIn = !!token;
 
   const loginHandler = (token) => {
       setToken(token)
 };
 
 const logoutHandler = () => {
    setToken(null)
 };
 
 const contextValue = {
     token: token,
   isLoggedIn: userIsLoggedIn,
     login: loginHandler,
      logout: logoutHandler,
 }
 
    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
 
 }
 
 
 export default AuthContext;